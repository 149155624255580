<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="IO hinzufügen"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew(null)"
          />
          <Button
            label="IO löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <label class="text-reader fg-black bg-lightTeal">
            <i class="fi fi-rr-upload" />
            Upload Schema
            <input type="file" @change="uploadJsonFile" />
          </label>
          <!-- <Button
            label="Download Schema"
            icon="pi pi-download"
            class="p-button-info ml-2"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          /> -->
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="io_data"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="50"
        v-model:filters="filters"
        filterDisplay="row"
        sortField="scheme"
        :sortOrder="1"
        :globalFilterFields="['metadata.label', 'metadata.description', 'metadata.department', 'metadata.category']"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Modulen"
        responsiveLayout="scroll"
        :getCategory="getCategory"
      >
        <template #empty> Keine IO-Einträge gefunden...</template>
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-2 m-md:0 as-md-center">Modulschemen verwalten</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="suche Eintrag..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="scheme" header="Schema" :sortable="true" filterField="scheme">
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
            <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="label" header="Name" :sortable="true" filterField="label">
          <template v-if="fullSearch === true" #filter="{filterModel, filterCallback}">
              <InputText v-model="filterModel.value" @keyup="filterCallback()" placeholder="suchen..." />
          </template>
        </Column>
        <Column field="created" header="Erstellt" :sortable="true">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.created) }}
          </template>
        </Column>
        <Column field="updated" header="Updated" :sortable="true">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.updated) }}
          </template>
        </Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-info mr-2"
              @click="editProduct(slotProps.data)"
            />
            <Button
              icon="pi pi-copy"
              class="p-button-rounded p-button-secondary mr-2"
              @click="openNew(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning mr-2"
              @click="confirmDeleteProduct(slotProps.data)"
            />
            <Button
              icon="fi fi-rr-download"
              class="p-button-rounded p-button-info"
              @click="exportJson(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="productDialog"
      header="Schema Konfiguration"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
    >

        <div class="formgrid grid">
          <div class="field col">
            <label for="name">Name</label>
            <InputText
              id="label"
              v-model.trim="ioDialogData.label"
              required="true"
              autofocus
              :class="'inputfield' + { invalid: submitted && !ioDialogData.label }"
            />
            <small class="error" v-if="submitted && !ioDialogData.label"
              >Name <b>muss</b> ausgefüllt werden!</small
            >
          </div>
        </div>
        <div class="formgrid grid">
          <div class="field col">
            <label for="name">Schemen-ID</label>
            <InputText
              id="scheme"
              v-model.trim="ioDialogData.scheme"
              required="true"
              autofocus
              :class="'inputfield' + { invalid: submitted && !ioDialogData.scheme }"
            />
            <small class="error" v-if="submitted && !ioDialogData.label"
              >Schemen-ID <b>muss</b> ausgefüllt werden!</small
            >
          </div>
        </div>

        <div id="selectList" class="formgrid grid mt-2">
          <div class="field col-7">
            <Listbox 
            v-model="selectedSchemeEntry" 
            :options="ioDialogData.children" 
            filter 
            optionLabel="label"
            listStyle="max-height:400px"
            >
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <div>
                      <span class="fg-lightBlue text-small">[
                        <span v-if="slotProps.option.writeable" class="fg-lightGreen">WR</span>
                        <span v-else class="fg-darkGray line-through">WR</span>|
                        <span v-if="slotProps.option.record" class="fg-green">REC</span>
                        <span v-else class="fg-darkGray line-through">REC</span>
                      ] </span>
                      <span class="fg-lightBlue">{{ slotProps.option.key }}</span>
                      <span class="fg-lightCobalt"> -> </span>
                      {{ slotProps.option.label }} <span class="text-small">{{ slotProps.option.unit }}</span>
                    </div>
                </div>
            </template>
          </Listbox>
          <div class="formgrid grid mt-2">
            <div class="field col-4">
              <Button
                  label="erstellen"
                  icon="pi pi-plus"
                  class="p-button-success mr-2"
                  @click=addSchemeEntry()
                />
            </div>
            <div class="field col-4">
              <Button
                  label="duplizieren"
                  icon="pi pi-copy"
                  class="p-button-success mr-2"
                  @click=duplicateSchemeEntry(selectedSchemeEntry)
                  :disabled="selectedSchemeEntry === null"
                />
            </div>
            <div class="field col-4">
              <Button
                  label="löschen"
                  icon="pi pi-minus"
                  class="p-button-danger mr-2"
                  @click=removeSchemeEntry(selectedSchemeEntry)
                  :disabled="selectedSchemeEntry === null"
                />
            </div>
          </div>
          
          </div>
          
          <div class="field col-5">
            <div v-if="selectedSchemeEntry !== null" class="formgrid grid">
              <div class="field col-12">
                <label for="key">Key (eindeutiger Variablenname)</label>
                <InputText
                  id="key"
                  v-model.trim="selectedSchemeEntry.key"
                  autofocus
                  :class="'inputfield'"
                />
              </div>
              <div class="field col-12">
                <label for="label">Bezeichnung (Label)</label>
                <InputText
                  id="label"
                  v-model="selectedSchemeEntry.label"
                  autofocus
                  :class="'inputfield'"
                />
              </div>
              <div class="field col-12">
                <label for="description">Beschreibung</label>
                <InputText
                  id="description"
                  v-model="selectedSchemeEntry.description"
                  autofocus
                  :class="'inputfield'"
                />
              </div>
              <div class="field col-6">
                <label for="unit">Einheit</label>
                <Dropdown
                  id="unit"
                  v-model="selectedSchemeEntry.unit"
                  placeholder="Einheit auswählen"
                  :options="getUnits"
                  optionLabel="label"
                  optionValue="label"
                >
                <template #value="slotProps">
                  <div>
                    <span :class="slotProps.value"></span>
                    <span class="text-lg">{{ slotProps.value }}</span>
                  </div>
                </template>
                <template #option="slotProps">
                  <div>
                    <span>{{ `${slotProps.option.label}`  }}</span>
                  </div>
                </template>
                </Dropdown>
              </div>
              <div class="field col-6">
                <label for="icon">Icon</label>
                <Dropdown
                  id="icon"
                  v-model="selectedSchemeEntry.icon"
                  :filter="true"
                  placeholder="Icon wählen"
                  :options="getIcons"
                  optionLabel="key"
                  optionValue="icon"
                >
                  <template #value="slotProps">
                    <div>
                      <span class="text-lg" :class="slotProps.value"></span>
                      <span class="text-lg"> [{{ slotProps.value }}]</span>
                    </div>
                  </template>

                  <template #option="slotProps">
                    <div>
                      <span class="text-5xl" :class="slotProps.option.icon"></span>
                      <span class="text-small"> [{{ slotProps.option.key }}]</span>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="field col-6">
                <label for="factor">Faktor (Wert/Faktor)</label>
                <Dropdown
                  id="factor"
                  v-model="selectedSchemeEntry.factor"
                  placeholder="Faktor auswählen"
                  :options="getObjectFactor"
                  optionLabel="label"
                  optionValue="value"
                >
                  <template #value="slotProps">
                    <div>
                      <span class="text-lg">{{ slotProps.value }}</span>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="field col-6">
                <label for="decimal">Dezimalstellen</label>
                <Dropdown
                  id="decimal"
                  v-model="selectedSchemeEntry.decimal"
                  placeholder="Dezimalstellen auswählen"
                  :options="getObjectDecimal"
                  optionLabel="label"
                  optionValue="value"
                >
                </Dropdown>
              </div>
              <div class="field col-12">
                <div class="checkbox">
                  <label for="arrayType">Schreibberechtigung </label>
                  <Checkbox id="writable" v-model="selectedSchemeEntry.writeable" :binary="true" />
                </div>
              </div>
              <div class="field col-6">
                <div class="checkbox">
                  <label for="arrayType">Record </label>
                  <Checkbox id="record" v-model="selectedSchemeEntry.record" :binary="true" />
                </div>
              </div>
              <div class="field col-6">
                <div class="checkbox">
                  <label for="cumulative">Kummuliert </label>
                  <Checkbox id="cumulative" v-model="selectedSchemeEntry.cumulative" :binary="true" />
                </div>
              </div>
              <div v-if="selectedSchemeEntry.isArray" class="field col-12">
                <div class="checkbox">
                  <label for="arrayType">Array-Type </label>
                  <Checkbox id="arrayType" v-model="selectedSchemeEntry.isArray" :binary="true" />
                </div>
              </div>
              <div class="field col-12">
                <label for="key2">Interner Modulpfad</label>
                <InputText id="key2" v-model="selectedSchemeEntry.key2" />
              </div>
            </div>
          </div>
      </div>
      <div class="formgrid grid">
        <div class="field col">
          <label>MongoDB Modul-ID</label>
          <InputText id="_id" v-model="ioDialogData._id" readonly />
        </div>
      </div>

      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.scheme }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductsDialog"
      :style="{ width: '450px' }"
      header="Bestätigung"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData"
          >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
        >
      </div>
      <template #footer>
        <Button
          label="Nein"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductsDialog = false"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProducts"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
// import axios from 'axios'
import { FilterMatchMode } from "primevue/api";
import store from "@/store";
import { mapGetters } from "vuex";
// import tree from "@/data/tree.json";
import { formatDateLog, combineNodeId, combineRedisHash, mongoResponseToast } from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";

export default {
  name: "moduleScheme",
  data() {
    return {
      loading: false,
      fullSearch: false,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      ioDialogData: {
        key: null,
        scheme: null,
        label: null,
        children: [],
      },
      io_type: null,
      // opc_browse: {},
      opc_browse: [],
      opcTree: [],
      opcTreeSelection: null,
      opcTreeExpandedKeys: null,
      opcTreeSelectedNode: null,
      io_data: null,
      selectedIOs: null,
      filters: null,
      submitted: false,
      intval_Value: null,
      file: null,
      selectedSchemeEntry: null,
      schemeTemplate: {
        key: 'NewEntry',
        key2: 'Scheme',
        label: 'Neuer Eintrag',
        description: 'Neuer Eintrag',
        unit: '°C',
        factor: 1,
        decimal: 1,
        icon: 'mif-florist',
        writeable: false,
        record: false
      },
    };
  },
  productService: null,
  computed: {
    ...mapGetters({
      getUserGroup: 'users/getUserGroups',
      getCategory: 'types/getCategory',
      getDepartments: 'types/getDepartments',
      getUnits: 'types/getUnits',
      getIcons: 'types/getIcons',
      getModules: 'opcua/getModules',
      getModuleScheme: 'opcua/getModuleScheme',
      getModuleTypes: 'opcua/getModuleTypes',
      getModuleBindings: 'opcua/getModuleBindings',
      getObjectFactor: 'getObjectFactor',
      getObjectDecimal: 'getObjectDecimal',
    }),
    nodeId: function () {
      return combineNodeId(this.ioDialogData.source.ns, this.ioDialogData.source.identifier);
    },
    redisHash: function () {
      return combineRedisHash(this.ioDialogData.source.server, this.ioDialogData.source.identifier);
    },
    nodeEntries: function () {
      return store.getters.getValues;
    },
  },
  watch: {
    nodeEntries: {
      handler: function() {
        this.getParameterStatusIO();
      },
      deep: true
    },
    selectedIOs: {
      handler: function() {
        console.log(this.selectedIOs);
      }
    }
  },
  created() {
    // this.productService = new ProductService();
    this.initFilters();
    this.$store.dispatch('users/loadUserGroups');
    this.$store.dispatch('types/loadUnitTypes');
    this.$store.dispatch('types/loadCategoryTypes');
    this.$store.dispatch('types/loadDepartmentTypes');
    this.$store.dispatch('types/loadIconTypes');
    this.$store.dispatch('opcua/loadModules');
    this.$store.dispatch('opcua/loadModuleScheme');
    this.$store.dispatch('opcua/loadModuleTypes');
    this.$store.dispatch('opcua/loadModuleBindings');
  },
  mounted() {
    // this.productService.getProducts().then((data) => (this.products = data));
    // this.loading = true;
    // this.registerSocketIoEvents();
    // this.getParameter();
    this.getPageData();
  },
  beforeUnmount() {
    this.loading = false;
    this.ioDialogData = {};
    this.selectedIOs = null;
    this.submitted = false;
    this.io_data = null;
    this.selectedSchemeEntry = null;
    this.schemeTemplate = null;
  },
  methods: {
    handleFileUpload(event){
      this.file = event.target.files[0];
      console.log(this.file);
    },
    uploadJsonFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = e => {
        console.log(e.target.result);
        let jsonObj = null;
        try {
          jsonObj = JSON.parse(e.target.result);
        } catch (e) {
          return false;
        }
        this.checkScheme(jsonObj);
        return true;
      }
      reader.readAsText(file);
    },
    checkScheme(scheme) {
      console.log(scheme);
      console.log(this.io_data);
      const exists = (this.io_data.find((item) => item.scheme === scheme.scheme)) || (this.io_data.find((item) => item.label === scheme.label));
      if (!exists) {
        this.ioDialogData = { ...scheme };
        if (this.ioDialogData._id) delete this.ioDialogData._id;
        this.ioDialogData.key = '';
        this.saveProduct();
      } else {
        this.$toast.add({
            severity: 'error',
            summary: 'Fehler!',
            detail: 'Eintrag mit Schema oder Name bereits vorhanden!',
            life: 3000,
        });
      }
    },
    exportJson(ioData) {
      if (ioData && ioData.scheme) {
        const filename = `${ioData.scheme}.json`;
        this.saveAsFile(filename, ioData);
      }
    },
    saveAsFile(filename, data) {
      const blob = new Blob([JSON.stringify(data)]);
      const link = document.createElement("a");
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      link.click();
    },
    resetDialogData() {
      this.ioDialogData =  {
        key: null,
        scheme: null,
        label: null,
        children: [],
      },
      this.selectedSchemeEntry = null;
    },
    getPageData() {
      this.loading = true;
      SocketioService.getModuleScheme((err, response) => {
        if (!err && response !== null) {
          this.io_data = [ ...response ];
          this.loading = false;
        }
      });
    },
    getOpcDirectory() {
      this.loading = true;
      SocketioService.getOpcuaBrowse(null, (err, response) => {
        if (!err && response !== null && response.length > 0) {
          response.forEach((tree) => {
            this.opcTree.push(tree.children[0]);
          });
          this.loading = false;
        }
      });
    },
    openNew(ioData) {
      this.resetDialogData();
      this.getOpcDirectory();
      console.log(ioData);
      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        this.ioDialogData = _obj;
        this.ioDialogData._id = null;
      } else {
        this.ioDialogData.label = 'Neues Schema';
        this.ioDialogData.key = '';
        this.ioDialogData.scheme = 'moduleScheme';
        this.ioDialogData.children.push(this.schemeTemplate);
      }
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
      clearInterval(this.intval_Value);
      this.resetDialogData();
    },
    saveProduct() {
      this.submitted = true;

      // if (this.ioDialogData.label.trim() && this.ioDialogData.key.trim()) {
      if (this.ioDialogData.label.trim()) {
        SocketioService.setModuleScheme(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      clearInterval(this.intval_Value);
      this.productDialog = false;
      this.resetDialogData();
    },
    editProduct(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.productDialog = true;
    },
    confirmDeleteProduct(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      if (this.ioDialogData._id) {
        SocketioService.delModuleScheme(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
      }
      this.deleteProductDialog = false;
      this.resetDialogData();
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    findString(_str, _arr) {
      // let _index;
      // _index = _arr.indexOf(_str);
      // return _index;
      for (let i = 0; i < _arr.length; i++) {
        if (_arr[i]["value"] == _str) {
          return _arr[i]["label"];
        }
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.io_data.length; i++) {
        if (this.io_data[i]._id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      if (this.selectedIOs.length > 0) {
        this.selectedIOs.forEach((entry) => {
          SocketioService.delModuleScheme(entry, (err, response) => {
            if (!err && response !== null) {
              this.loading = false;
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getPageData();
            }
          });
        }); 
      }
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    addSchemeEntry() {
      this.ioDialogData.children.push({ ...this.schemeTemplate });
    },
    duplicateSchemeEntry(selectedNode) {
      if (selectedNode) {
        let newNode = { ...selectedNode};
        newNode.key = `${newNode.key}_NEW`;
        this.ioDialogData.children.push({ ...newNode });
      }
    },
    removeSchemeEntry(selectedNode) {
      if (selectedNode) {
        const index = this.ioDialogData.children.findIndex(element => element === selectedNode);
        if (index !== -1) {
          this.ioDialogData.children.splice(index, 1);
        }
      }
    },
    formatDate(timestamp) {
      return formatDateLog(timestamp);
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'metadata.category': { value: null, matchMode: FilterMatchMode.IN },
        'metadata.department': { value: null, matchMode: FilterMatchMode.IN },
        'metadata.label': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'metadata.description': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .p-multiselect-representative-option {
        display: inline-block;
        vertical-align: middle;
    }
  .text-reader {
    position: relative;
    overflow: hidden;
    display: inline-block;

    /* Fancy button style 😎 */
    border: 2px solid black;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
  }
  .text-reader input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
</style>